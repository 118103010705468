import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    AddFavoriteFolderButton,
    DashboardCard,
    DragAndDropFavoritesList,
    Favorites,
    filterAndSortActions,
    filterFavoritesTabConfig,
    FilterUtils,
} from '@yonder-mind/ui-core';
import { IWebApplicationStore } from '../../../interfaces';

export const DragAndDropFavoritesCard = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const hasErrorFavorites = useSelector((state: IWebApplicationStore) => state.user.hasError);
    const isFirstLoading = useSelector((state: IWebApplicationStore) => state.docs.isFirstLoading);
    const isLoading = useSelector((state: IWebApplicationStore) => state.docs.isLoading);
    const isFavoritesLoading = useSelector((state: IWebApplicationStore) => state.userSettings.isFavoritesLoading);
    const { filteredFavoritesData } = useSelector((state: IWebApplicationStore) => state.filterAndSort);
    const { activeFilters } = filteredFavoritesData;

    useEffect(() => {
        if (!isFavoritesLoading && !isFirstLoading && !isLoading) {
            dispatch(filterAndSortActions.saveFilterFavoritesValues(activeFilters));
        }
    }, [filteredFavoritesData]);

    return (
        <DashboardCard
            isLoading={isFirstLoading || isLoading || isFavoritesLoading}
            hasError={hasErrorFavorites}
            primaryHeader={t('dashboard.cards.favorites.title')}
            secondaryHeader={t('dashboard.cards.favorites.subtitle')}
            icon={<Favorites size="large" />}
            headerTrailingComponent={<AddFavoriteFolderButton />}
            headerTrailingComponentVisibleOnTabs={[0]}
            tabs={[
                {
                    component: <DragAndDropFavoritesList />,
                    dataTestId: 'dashboardFavoritesCardTabDocs',
                    label: t('dashboard.cards.favorites.tabs.documents'),
                    chips: {
                        filterAvailable: true,
                        filterType: filterFavoritesTabConfig.type,
                        filterValues: activeFilters,
                    },
                },
            ]}
            filterOptions={FilterUtils.getFavoriteCardFilterOptions({
                t,
                filteredFavoritesData: filteredFavoritesData,
            })}
        />
    );
};
