import {
    ClipboardApi,
    configureStore,
    FilterAndSortApi,
    IAuthApi,
    IAuthServiceApi,
    SidebarApi,
} from '@yonder-mind/ui-core';
import { RestHighlightsApi } from '../api/rest-highlights-api';
import { AppModeApi } from './appMode';
import { AppSettingsApi } from './appSettings';
import { RestNotificationsApi } from '../api/rest-notifications-api';
import { FolderRestApi } from '../api/Folder';
import { FormRestApi } from '../api/Form';
import { createTransform, persistReducer, persistStore } from 'redux-persist';
import localStorage from 'redux-persist/es/storage';
import authSaga from './auth/auth-sagas';
import changesSaga from './changes/changes-sagas';
import pdfExportSagas from './pdfexport/pdfexport-sagas';
import { PdfExportApi } from '../api/rest-pdfexport-api';
import userSaga from './user/user-sagas';
import websocketSagas from './websocket/websocket.sagas';
import workflowSagas from './workflow/workflow-sagas';
import { RestWorkflowApi } from '../api/rest-workflow-api';
import { importSagas } from './import';
import { complianceReportsSagas } from './complianceReports';
import { RestComplianceReportsApi } from '../api/rest-compliance-reports-api';
import complianceReportsReducer from './complianceReports/compliance-reports-reducer';
import importJobReducer from './import/import-job-reducer';
import pdfExportReducer from './pdfexport/pdfexport-reducer';
import websocketReducer from './websocket/websocket.reducer';
import workflowReducer from './workflow/workflow-reducer';
import { RestDbApi } from '../api/rest-db-api';
import { TaskRestApi } from '../api/Task';
import { UserRestApi } from '../api/User';
import { FlashCardRestApi } from '../api/FlashCard';
import { LinkRestApi } from '../api/Link';
import { RestWebDbApi } from '../api/rest-web-db-api';
import { IWebApplicationStore } from '../interfaces';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { CompareRevisionsRestApi } from '../api/CompareRevisions/CompareRevisionsRestApi';
import { FilesRestApi } from '../api/Files';
import historyLogsReducer from './historyLog/historyLogSlice';
import historyLogSagas from './historyLog/historyLog-sagas';
import * as HistoryLogApi from '../api/HistoryLog/history-log-api';

const api = new RestDbApi();
const flashCardApi = new FlashCardRestApi();
const linkApi = new LinkRestApi();
const taskApi = new TaskRestApi();
const userApi = new UserRestApi();
const webApi = new RestWebDbApi();

export const setupStore = (authApi: IAuthApi, authService: IAuthServiceApi) => {
    const uiWebSagas = [
        authSaga(),
        changesSaga(webApi),
        complianceReportsSagas(new RestComplianceReportsApi()),
        historyLogSagas(HistoryLogApi),
        importSagas(webApi),
        pdfExportSagas(new PdfExportApi()),
        userSaga(userApi),
        websocketSagas(authService),
        workflowSagas(new RestWorkflowApi(), webApi),
    ];

    const uiWebReducers = {
        complianceReports: complianceReportsReducer,
        historyLogs: historyLogsReducer,
        import: importJobReducer,
        pdfExport: pdfExportReducer,
        websocket: websocketReducer,
        workflow: workflowReducer,
    };

    const store = configureStore({
        initialState: {},
        context: {
            api,
            taskApi,
            userApi,
            flashCardApi,
            linkApi,
            authServiceApi: authService,
            highlightsApi: new RestHighlightsApi(),
            appModeApi: new AppModeApi(authApi),
            appSettingsApi: new AppSettingsApi(authApi),
            clipboardApi: new ClipboardApi(authApi),
            sidebarApi: new SidebarApi(authApi),
            filterAndSortApi: new FilterAndSortApi(authApi),
            notificationsApi: new RestNotificationsApi(),
            folderApi: new FolderRestApi(),
            formApi: new FormRestApi(),
            compareRevisionsApi: new CompareRevisionsRestApi(),
            filesApi: new FilesRestApi(),
        },
        extraSagas: uiWebSagas,
        extraReducers: uiWebReducers,
        persistReducer: (reducer: any) => {
            return persistReducer(
                {
                    key: 'highlights',
                    storage: localStorage,
                    whitelist: ['highlights'],
                    transforms: [
                        createTransform(
                            (state) => {
                                return { ...state, highlights: {}, isNoteOpen: false };
                            },
                            null,
                            { whitelist: ['highlights'] }
                        ),
                    ],
                },
                reducer
            );
        },
    });

    const persistor = persistStore(store);

    return { store, persistor };
};

export const useSelectorUiWeb: TypedUseSelectorHook<IWebApplicationStore> = useSelector;
