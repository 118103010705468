import * as React from 'react';
import {
    CloseButton,
    IApiContextVersion,
    IWorkflowFormField,
    IWorkflowTaskInfo,
    YonderButton,
} from '@yonder-mind/ui-core';
import { IFormValues } from '../../../interfaces';
import { WorkflowForm } from '../../FormStructure';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { WorkflowTaskVariant } from '.';
import { useDispatch } from 'react-redux';
import { processActions } from '../../../store';

const confirmFormFieldType = 'confirm';

interface WorkflowTaskProps {
    contextVersion: IApiContextVersion;
    task: IWorkflowTaskInfo;
    crModuleVersionOid?: string;
    isDisabled: boolean;
    onClick: () => void;
    variant: WorkflowTaskVariant;
}

export const WorkflowTask: React.FC<WorkflowTaskProps> = ({
    contextVersion,
    task,
    crModuleVersionOid,
    isDisabled,
    onClick,
    variant,
}) => {
    const { t } = useTranslation();
    const [form, setForm] = React.useState<IWorkflowFormField[] | null>(null);

    const [confirmationDialog, setConfirmationDialog] = React.useState<IWorkflowFormField | null>(null);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState<boolean>(false);
    const [confirmationDialogConfirmed, setConfirmationDialogConfirmed] = React.useState<boolean>(false);

    const dispatch = useDispatch();
    React.useEffect(() => {
        const confirmationField = task.formFields.find((field) => field?.type?.name === confirmFormFieldType);

        if (confirmationField) {
            setConfirmationDialog(confirmationField);
        }
    }, [task]);

    if (!contextVersion) {
        return null;
    }

    const hasForm = () => {
        if (confirmationDialog) {
            return task.formFields && task.formFields.length > 1;
        } else {
            return task.formFields && task.formFields.length > 0;
        }
    };

    const executeTask = () => {
        if (hasForm()) {
            const displayableFields = task.formFields.filter((field) => field?.type?.name !== confirmFormFieldType);
            setForm(displayableFields);
        } else {
            onClick();
            dispatch(
                processActions.completeTask({
                    taskId: task.id,
                    processInstanceId: task.processInstanceId,
                    data: {},
                    documentOid: contextVersion?.oid,
                    contextId: contextVersion?.contextOid,
                    variant,
                })
            );
        }
    };

    const tryExecuteTask = () => {
        if (confirmationDialog && !confirmationDialogConfirmed) {
            setConfirmationDialogOpen(true);
            return;
        }
        executeTask();
    };

    const cancelForm = () => {
        setConfirmationDialogConfirmed(false);
        setForm(null);
    };

    const sendForm = (data: IFormValues) => {
        onClick();
        dispatch(
            processActions.completeTask({
                taskId: task.id,
                processInstanceId: task.processInstanceId,
                data,
                documentOid: contextVersion?.oid,
                contextId: contextVersion?.contextOid,
                variant,
            })
        );
        setForm(null);
    };

    const handleConfirmationCancel = () => {
        setConfirmationDialogConfirmed(false);
        setConfirmationDialogOpen(false);
    };

    const handleConfirmed = () => {
        setConfirmationDialogConfirmed(true);
        setConfirmationDialogOpen(false);
        executeTask();
    };

    return (
        <>
            <YonderButton
                variant="contained"
                size="large"
                className="workflow-task"
                disabled={isDisabled}
                onClick={tryExecuteTask}
                children={task.name}
                fullWidth={true}
                data-testid={task.name}
            />

            <Dialog open={confirmationDialogOpen} maxWidth="sm" fullWidth={true} role="modal" data-testid="modal">
                <DialogTitle disableTypography={true} data-testid="modalHeader">
                    <Grid container={true} alignItems="center">
                        <Typography variant="h6">{t('form.confirmationTitle')}</Typography>
                        <div className="grow" />
                        <CloseButton onClick={handleConfirmationCancel} size="small" data-testid="modalCloseButton" />
                    </Grid>
                </DialogTitle>
                <DialogContent data-testid="modalContent">
                    <p>{confirmationDialog && confirmationDialog.label}</p>
                </DialogContent>
                <DialogActions data-testid="modalActions">
                    <YonderButton variant="text" onClick={handleConfirmationCancel} data-testid="modalCancelButton">
                        {t('form.actions.cancel')}
                    </YonderButton>
                    <YonderButton variant="contained" onClick={handleConfirmed} data-testid="modalConfirmationButton">
                        {t('form.actions.confirm')}
                    </YonderButton>
                </DialogActions>
            </Dialog>

            <WorkflowForm
                open={!!form}
                title={task.name}
                fields={form || []}
                injectedFields={{
                    CONTEXT_ID: contextVersion?.contextOid,
                    DOCUMENT_ID: contextVersion?.oid,
                    ...(crModuleVersionOid ? { CHANGE_REQUEST_MODULE_VERSION_ID: crModuleVersionOid } : {}),
                }}
                onCancel={cancelForm}
                onSubmit={sendForm}
            />
        </>
    );
};
