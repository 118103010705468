import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { CloudUpload, CloudUploadOutlined } from '@material-ui/icons';
import {
    authSelector,
    FileFormData,
    filesActions,
    FileTypeEnum,
    FolderStructureUtils,
    useSelectorUiCore,
    YonderButton,
} from '@yonder-mind/ui-core';
import { FileDropTable } from './FileDropTable/FileDropTable';
import { FileDropHeader } from './FileDropHeader/FileDropHeader';
import { FileDropMetadata, FileDropTableItem, ImportedFiles, SelectedFileKeys } from './domain/types';
import { WidgetCard } from '../../components/WidgetCard/WidgetCard';
import { useFileDropZone } from './utils/useFileDropZone';
import { handleFileDropPublish } from './utils/handleFileDropPublish';
import {
    checkFileContextChanges,
    getContextMetadataFromSelectedFile,
    getCurrentMetadata,
    getFormDataFromFileForApi,
} from './utils/metadataUtils';
import { NoItems } from '../../components';
import { MemoDialog } from './Memo/MemoDialog';
import { MetadataTabs } from './MetadataTabs/MetadataTabs';
import isEqual from 'lodash/isEqual';

export const FileDropImport: React.FC<{
    visible: boolean;
    updatedFile: FileDropTableItem;
    setUpdatedFile: (updatedFile: FileDropTableItem) => void;
}> = ({ visible, updatedFile, setUpdatedFile }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const hasFileDropEditorRole = useSelector(authSelector.hasFileDropEditorRole);

    const [memoOpened, setMemoOpened] = useState(false);
    const [importedFiles, setImportedFiles] = useState<ImportedFiles>({});
    const [selectedFileKeys, setSelectedFileKeys] = useState<SelectedFileKeys>([]);

    const { fileDropUploadStatus, tenantSettings, fileUploadCompleted, newFileList, assignedFilesToFolders, folders } =
        useSelectorUiCore((state) => {
            return {
                fileDropUploadStatus: state.files.fileUploadStatus,
                tenantSettings: state.tenantSettings.tenantSettings,
                fileUploadCompleted: state.files.fileUploadCompleted,
                newFileList: state.files.fileList,
                assignedFilesToFolders: state.files.assignedFilesToFolders,
                folders: state.folder.unprocessedFolders,
            };
        });

    const selectedFiles = useMemo(
        () => Object.values(importedFiles).filter((item) => selectedFileKeys.includes(item.key)),
        [importedFiles, selectedFileKeys]
    );
    const fileList = useMemo(() => Object.values(importedFiles), [importedFiles]);

    const currentMetadata = useMemo(
        () => getCurrentMetadata(selectedFileKeys, importedFiles),
        [importedFiles, selectedFileKeys]
    );

    useEffect(() => {
        if (updatedFile && updatedFile.file && updatedFile.key) {
            setUpdatedFile(null);
            setImportedFiles({ ...importedFiles, [updatedFile.key]: updatedFile });
            setSelectedFileKeys([updatedFile.key]);
        }
    }, [updatedFile]);

    useEffect(() => {
        if (!visible) {
            setSelectedFileKeys([]);
        }
    }, [visible]);

    useEffect(() => {
        if (fileUploadCompleted) {
            handleFileDropPublish({
                enqueueSnackbar,
                fileDropUploadStatus,
                importedFiles,
                setImportedFiles,
                setSelectedFileKeys,
                t,
            });
        }
    }, [enqueueSnackbar, fileUploadCompleted, t, fileDropUploadStatus, tenantSettings]);

    const setMetadata = useCallback(
        (metadata: FileDropMetadata) => {
            const newImportedFiles: ImportedFiles = {};
            selectedFileKeys.forEach((fileKey) => {
                const currentFile = importedFiles[fileKey];
                if (
                    metadata?.contextOid &&
                    metadata.contextOid !== currentFile.key &&
                    metadata.contextOid !== currentFile.fileIdToUpdate
                ) {
                    return;
                }
                newImportedFiles[fileKey] = { ...currentFile, ...metadata };
            });
            setImportedFiles({ ...importedFiles, ...newImportedFiles });
        },
        [importedFiles, selectedFileKeys]
    );

    const pushSelectedFileKey = useCallback(
        (key: string) => {
            selectedFileKeys.push(key);
            setSelectedFileKeys([...selectedFileKeys]);
        },
        [selectedFileKeys]
    );

    const { getRootProps, getInputProps, open, isDragAccept } = useFileDropZone({
        enqueueSnackbar,
        importedFiles,
        pushSelectedFileKey,
        setImportedFiles,
        t,
        tenantSettings,
    });

    useEffect(() => {
        setTimeout(() => Object.keys(currentMetadata).length && setMetadata(currentMetadata), 0);
    }, [selectedFileKeys]);

    const hasFolderAssignmentChanged = useCallback(() => {
        const originalAssignedFolders = FolderStructureUtils.getAssignedFolders(
            currentMetadata.contextOid,
            folders?.root?.children
        );
        const currentAssignedFolders = assignedFilesToFolders[currentMetadata.contextOid];
        return !isEqual(originalAssignedFolders, currentAssignedFolders);
    }, [currentMetadata.contextOid, folders, assignedFilesToFolders]);

    const onPublish = useCallback(() => {
        const files: FileFormData[] = [];
        selectedFiles.forEach((selectedFile) => {
            const formData = getFormDataFromFileForApi(selectedFile);
            files.push({
                file: formData,
                key: selectedFile.key,
                fileIdToUpdate: selectedFile.fileIdToUpdate,
                updateContextMetadata: checkFileContextChanges(selectedFile, newFileList)
                    ? getContextMetadataFromSelectedFile(selectedFile)
                    : null,
                assignToFolderOIDs: hasFolderAssignmentChanged()
                    ? assignedFilesToFolders[selectedFile.fileIdToUpdate || selectedFile.key]
                    : null,
                structureOid: folders.oid,
            });
        });
        dispatch(filesActions.filesUploadRequested(files));
    }, [dispatch, selectedFiles, assignedFilesToFolders]);

    const noDataComponent = () => {
        return (
            <NoItems
                onClick={open}
                className="file-drop-no-files-selected"
                message={t('fileDrop.importOrDragFiles')}
                icon={<CloudUpload className="file-drop-no-files-selected-icon" />}
            />
        );
    };

    const createMemo = () => {
        setMemoOpened(true);
    };

    const importMemoFile = (file: FileDropTableItem, fileKey: string) => {
        setImportedFiles({
            ...importedFiles,
            [fileKey]: file,
        });
        pushSelectedFileKey(fileKey);
    };

    const downloadFile = ({ file, fileName, fileType }: { file: File; fileName: string; fileType: string }) => {
        const blob = new Blob([file], { type: fileType });
        const a = document.createElement('a');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        });
        a.dispatchEvent(clickEvt);
        a.remove();
    };

    const handlePreview = (fileKey: string) => {
        const item = importedFiles[fileKey];
        if (item.type === FileTypeEnum.pdf) {
            const fileURL = URL.createObjectURL(item.file);
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;
        } else {
            downloadFile({ file: item.file, fileName: item.name, fileType: item.type });
        }
    };

    return (
        visible && (
            <div {...getRootProps()} className="file-drop-page">
                <div className="file-drop-body">
                    <WidgetCard className="file-drop-table-card">
                        <div className="file-drop-header-buttons">
                            <FileDropHeader
                                openFileSelect={open}
                                title={t('fileDrop.importButton')}
                                getInputProps={getInputProps}
                            />
                            {hasFileDropEditorRole && (
                                <YonderButton onClick={createMemo} variant="outlined" data-testid="createMemo">
                                    {t('fileDrop.createMemo')}
                                </YonderButton>
                            )}
                        </div>
                        <FileDropTable
                            fileList={fileList}
                            selectedFileKeys={selectedFileKeys}
                            setSelectedFileKeys={setSelectedFileKeys}
                            noDataComponent={noDataComponent()}
                            kebabMenuProps={{
                                remove: (fileKey) => {
                                    const newImportedFiles = { ...importedFiles };
                                    delete newImportedFiles[fileKey];
                                    setImportedFiles(newImportedFiles);
                                    setTimeout(() => setSelectedFileKeys([]));
                                },
                                preview: (fileKey) => {
                                    handlePreview(fileKey);
                                },
                            }}
                        />
                    </WidgetCard>
                    <WidgetCard className="file-drop-metadata-card">
                        <MetadataTabs
                            type="import"
                            currentMetadata={currentMetadata}
                            selectedFileKeys={selectedFileKeys}
                            setMetadata={setMetadata}
                            onPublish={onPublish}
                        />
                    </WidgetCard>
                </div>
                {isDragAccept && (
                    <div className="file-drop-enter-layer">
                        <div className="file-drop-enter-layer__content">
                            <div className="file-drop-enter-layer__content__wrapper">
                                <CloudUploadOutlined className="file-drop-no-files-selected-icon-outlined" />
                                <p className="drop-icon-title">{t('fileDrop.file.drop')}</p>
                            </div>
                        </div>
                    </div>
                )}
                {hasFileDropEditorRole && (
                    <MemoDialog
                        open={memoOpened}
                        onClose={() => setMemoOpened(false)}
                        importFile={importMemoFile}
                        maxFileSize={tenantSettings.fileDropFileMaxSizeInMb}
                    />
                )}
            </div>
        )
    );
};
