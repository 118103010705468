import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Typography } from '@material-ui/core';
import { FileDropMetadata } from '../../domain/types';
import { MetadataDateSelector } from './MetadataDateSelector';
import { MetadataUsersTagsSelector } from './MetadataUsersTagsSelector';

export type MetadataPanelType = 'library' | 'import';
interface IProps {
    type: MetadataPanelType;
    selectedFileKeys: string[];
    setMetadata: (metadata: FileDropMetadata) => void;
    currentMetadata: FileDropMetadata;
    setNewValidation: (
        v: (prevValidation: MetadataValidation) => {
            name?: string;
            dateTo?: string;
            isEqualToCurrentMetadata?: boolean;
            dateFrom?: string;
        }
    ) => void;
    disabled?: boolean;
    visible: boolean;
}

export type MetadataValidation = {
    name?: string;
    dateFrom?: string;
    dateTo?: string;
    isEqualToCurrentMetadata?: boolean;
};

export const MetadataPanel: React.FC<IProps> = ({
    selectedFileKeys,
    setMetadata,
    currentMetadata,
    setNewValidation,
    disabled,
    type,
    visible,
}) => {
    const { t } = useTranslation();

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name: string = event.target.value;
        setMetadata({ ...currentMetadata, name });
        setNewValidation((prevValidation: MetadataValidation) => ({
            ...prevValidation,
            name: getFileNameErrorMessage(name),
        }));
    };

    const getFileNameErrorMessage = (fileName: string) => {
        if (!fileName?.trim()) return t('fileDrop.metadataPanel.fileName.error');
        return '';
    };

    return (
        visible && (
            <>
                <div className="metadata-validity">
                    <div>
                        {selectedFileKeys.length <= 1 && (
                            <div>
                                <Typography variant="h6" className="validity-title">
                                    {t('fileDrop.metadataPanel.fileName.label')}
                                </Typography>
                                <div className="metadata-file-name">
                                    <TextField
                                        data-testid="metadata-file-name"
                                        variant="outlined"
                                        disabled={disabled}
                                        placeholder={t('fileDrop.metadataPanel.fileName.placeholder')}
                                        value={currentMetadata?.name || ''}
                                        error={!!getFileNameErrorMessage(currentMetadata.name)}
                                        helperText={getFileNameErrorMessage(currentMetadata.name)}
                                        fullWidth
                                        onChange={onNameChange}
                                        inputProps={{ maxLength: 60 }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <Typography variant="h6" className="validity-title">
                        {t('fileDrop.metadataPanel.datepicker.validityRange')}
                    </Typography>
                    <Typography variant="subtitle2" className="validity-description">
                        {t('fileDrop.metadataPanel.datepicker.helpText')}
                    </Typography>
                    <div className="validity-range">
                        <MetadataDateSelector
                            key={selectedFileKeys.toString() + 'date-selector'}
                            disabled={disabled}
                            currentMetadata={currentMetadata}
                            setMetadata={setMetadata}
                            setValidation={setNewValidation}
                            type={type}
                        />
                    </div>
                </div>
                <MetadataUsersTagsSelector
                    disabled={disabled}
                    setMetadata={setMetadata}
                    currentMetadata={currentMetadata}
                />
            </>
        )
    );
};
