import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { IconButton, Tab as MaterialTab, Tabs as MaterialTabs } from '@material-ui/core';
import { DescriptionOutlined as Document } from '@material-ui/icons';
import {
    appSettingsActions,
    authSelector,
    ChangeRequestSplitViewTab,
    combineStrings,
    ContextVersionUtils,
    docsSelector,
    Empty,
    IApiContextVersion,
    IApiModuleVersion,
    IApplicationStore,
    IDocumentStatus,
    IQueryParam,
    IReaderTool,
    IWorkflowActivity,
    IWorkflowHistoricActivity,
    modulesSelector,
    ModuleVersionHeader,
    ModuleVersionUtils,
    OutlineUtils,
    PinButton,
    SnackBar,
    Spinner,
    SplitView,
    useHistory,
    userActions,
    WorkflowUtils,
} from '@yonder-mind/ui-core';
import { IWebApplicationStore } from '../../../interfaces';
import { useWorkflow } from '../../../context';
import { CRName, WorkflowCRProposalScreen } from '../../../config';
import { useSelectorUiWeb } from '../../../store';
import { DraftView, ModuleVersionView, TextProposalView } from './SplitViews';
import { ChangeRequestUtils } from '../../../utils';

interface ChangeRequestWorkflowSplitViewProps {
    document: IApiContextVersion;
    changeRequest: IWorkflowActivity | IWorkflowHistoricActivity;
    crModuleVersion: IApiModuleVersion | null;
    previousModuleVersion: IApiModuleVersion | null;
    setEditing: (isEditing: boolean) => void;
    onUnsavedChanges: (hasChanges: boolean) => void;
}

interface SwitchableTab {
    key: ChangeRequestSplitViewTab;
    label: React.ReactNode;
    dataTestId: string;
    className: string;
    disabled: boolean;
}

export const ChangeRequestWorkflowTabView: React.FC<ChangeRequestWorkflowSplitViewProps> = ({
    document,
    changeRequest,
    crModuleVersion,
    previousModuleVersion,
    setEditing,
    onUnsavedChanges,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { pushUrl } = useHistory();
    const { getCREdit } = useWorkflow('cr');

    const { params: routerParams } = useRouteMatch<{ documentOid: string }>();
    const { documentOid } = routerParams;
    const contextVersions = useSelectorUiWeb((state) => docsSelector.getVersions(state, documentOid));
    const hasDraftDocsAccess = useSelector(authSelector.hasDraftDocsAccess);
    const { userSettings } = useSelector((state: IWebApplicationStore) => state.user);
    const defaultChangeRequestSplitViewTab = userSettings.uiSettings?.defaultChangeRequestSplitViewTab;
    const userInfo = useSelector(authSelector.userInfo);

    const [selectedTab, setSelectedTab] = useState<ChangeRequestSplitViewTab>(ChangeRequestSplitViewTab.ACTUAL);
    const [timedOut, setTimedOut] = useState<boolean>(false);
    const [isEditingTextProposal, setEditingTextProposal] = useState(false);

    useEffect(() => {
        dispatch(appSettingsActions.diffingSettingsRequested());
        dispatch(appSettingsActions.diffingProposalSettingsRequested());
    }, []);

    useEffect(() => {
        if (defaultChangeRequestSplitViewTab && defaultChangeRequestSplitViewTab !== ChangeRequestSplitViewTab.ACTUAL) {
            setSelectedTab(defaultChangeRequestSplitViewTab);
        }
    }, [defaultChangeRequestSplitViewTab]);

    useEffect(() => {
        setEditing(isEditingTextProposal);
    }, [isEditingTextProposal]);

    const isModuleDiffingDraftActive = useSelector(
        (state: IWebApplicationStore) => state.appSettings.moduleDiffingDraft
    );
    const isModuleDiffingProposalActive = useSelector(
        (state: IWebApplicationStore) => state.appSettings.moduleDiffingProposal
    );

    const previousVersion = useSelector((state: IApplicationStore) =>
        modulesSelector.previousVersion(state, crModuleVersion?.moduleOid, crModuleVersion?.oid)
    );

    const isDeleteCR = WorkflowUtils.isChangeRequestTypeOf(changeRequest, CRName.DELETE_MODULE);
    const isEditContentCR = WorkflowUtils.isChangeRequestTypeOf(changeRequest, CRName.EDIT_CONTENT);
    const isEditTitleCR = WorkflowUtils.isChangeRequestTypeOf(changeRequest, CRName.EDIT_TITLE);
    const isAddModuleCR = WorkflowUtils.isChangeRequestTypeOf(changeRequest, CRName.ADD_MODULE);
    const useEditor = changeRequest.variables.PROPOSAL_SCREEN === WorkflowCRProposalScreen.EDITOR;
    const canEditTextProposal = WorkflowUtils.canEditTextProposal(changeRequest, userInfo.roles);
    const isCRInImplementationPhase = WorkflowUtils.isInImplementationPhase(changeRequest);
    const isCRCompleted = WorkflowUtils.isCompleted(changeRequest);
    const isCRRejected = WorkflowUtils.isObsolete(changeRequest);
    const isInApprovalPhase =
        !isCRInImplementationPhase &&
        !canEditTextProposal &&
        ChangeRequestUtils.isChangeRequestInApprovalPhase(changeRequest.childActivityInstances);

    const isCRInOrAfterImplementationPhaseButNotRejected =
        isCRInImplementationPhase || isInApprovalPhase || (isCRCompleted && !isCRRejected);

    const contextVersionToEdit = contextVersions?.find(
        (v) => v.oid === WorkflowUtils.getContextVersionToEditOid(changeRequest.variables)
    );

    const getPreviousContextVersion = () => {
        if (contextVersions?.length > 1 && contextVersionToEdit) {
            // We are getting only released ones because we can have temporary revision and regular revision open at the same time
            const versionsSorted = ContextVersionUtils.sortByLatestEffective(contextVersions).filter(
                (v) => v.status === IDocumentStatus.RELEASED
            );
            const indexOfContextVersionToEdit = versionsSorted.indexOf(contextVersionToEdit);
            if (versionsSorted.length === indexOfContextVersionToEdit + 1) {
                return undefined;
            }
            return versionsSorted[indexOfContextVersionToEdit + 1];
        }
        return undefined;
    };

    let previousContextVersion = getPreviousContextVersion();
    // If there is no previous context version use contextVersionToEdit
    if (!previousContextVersion) {
        previousContextVersion = contextVersionToEdit;
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimedOut(true);
        }, 5000);
        return () => clearTimeout(timer);
    }, []);

    if (crModuleVersion === null) {
        return (
            <>
                <Spinner />
                {timedOut && (
                    <SnackBar
                        key={0}
                        isOpen={true}
                        message={t('workflow.revision.errors.notPublished')}
                        variant="warning"
                        position="fixed"
                    />
                )}
            </>
        );
    }

    const crEdit = getCREdit(changeRequest.processInstanceId);

    const getModuleVersionTitle = () => {
        if (crEdit && crEdit.title && crEdit.title.moduleVersionTitle) {
            return crEdit.title.moduleVersionTitle;
        }

        if (isDeleteCR) {
            return '';
        }

        if (changeRequest.variables.NEW_MODULE_VERSION_TITLE) {
            return changeRequest.variables.NEW_MODULE_VERSION_TITLE;
        }

        if (!contextVersionToEdit) {
            return t('reader.moduleVersion.notFound');
        }

        return contextVersionToEdit.moduleVersionOutlineInfo[crModuleVersion.moduleOid]?.moduleVersionTitle;
    };

    const moduleVersionNumbering = isDeleteCR
        ? ''
        : contextVersionToEdit && ModuleVersionUtils.getModuleVersionNumbering(contextVersionToEdit, crModuleVersion);
    const moduleVersionTitle = getModuleVersionTitle();

    // If change request is released and is content change request previous module version is not correct so we need to fix it
    const fixedPreviousModuleVersion =
        WorkflowUtils.isReleased(changeRequest, contextVersions) && isEditContentCR
            ? previousVersion
            : previousModuleVersion;

    const previousModuleVersionNumbering = isAddModuleCR
        ? ''
        : fixedPreviousModuleVersion &&
          previousContextVersion &&
          ModuleVersionUtils.getModuleVersionNumbering(previousContextVersion, fixedPreviousModuleVersion);

    const previousModuleVersionTitle = isAddModuleCR
        ? ''
        : fixedPreviousModuleVersion && previousContextVersion
        ? previousContextVersion.moduleVersionOutlineInfo[fixedPreviousModuleVersion.moduleOid]?.moduleVersionTitle ??
          t('reader.moduleVersion.notFound')
        : t('reader.moduleVersion.notFound');

    const openDoc = () => {
        pushUrl(`/doc/${document.oid}/${getModulePath()}`, [
            {
                key: IQueryParam.READER_TOOLS,
                value: IReaderTool.MODULE_VIEW,
            },
        ]);
    };

    const getModulePath = () => {
        return OutlineUtils.findModuleInOutline(document.outline, crModuleVersion.moduleOid).path.join('/');
    };

    const toggleDraftDiffing = () => {
        dispatch(appSettingsActions.saveDiffingSettingsRequested(!isModuleDiffingDraftActive));
    };

    const toggleProposalDiffing = () => {
        dispatch(appSettingsActions.saveDiffingProposalSettingsRequested(!isModuleDiffingProposalActive));
    };

    const isTitleChanged = previousModuleVersionTitle === moduleVersionTitle;

    const showTitleInDraftTab =
        (!isDeleteCR && isCRInOrAfterImplementationPhaseButNotRejected) ||
        (isEditTitleCR && isTitleChanged ? isCRInImplementationPhase : true);

    const textProposalHeaderTitle = isEditTitleCR
        ? combineStrings([moduleVersionNumbering, WorkflowUtils.getEditTitleChangeRequestProposalTitle(changeRequest)])
        : combineStrings([moduleVersionNumbering, moduleVersionTitle]);

    const actualView = {
        header: (
            <ModuleVersionHeader text={combineStrings([previousModuleVersionNumbering, previousModuleVersionTitle])} />
        ),
        content: (
            <ModuleVersionView
                document={previousContextVersion}
                changeRequest={changeRequest}
                moduleVersion={
                    previousModuleVersionTitle === t('reader.moduleVersion.notFound')
                        ? null
                        : fixedPreviousModuleVersion
                }
            />
        ),
    };

    const proposalView = {
        header: <ModuleVersionHeader text={textProposalHeaderTitle} />,
        content: (
            <TextProposalView
                onUnsavedChanges={onUnsavedChanges}
                document={previousContextVersion}
                changeRequest={changeRequest}
                crModuleVersion={crModuleVersion}
                useEditor={useEditor}
                canEditTextProposal={canEditTextProposal}
                isEditingTextProposal={isEditingTextProposal}
                isEditTitleCR={isEditTitleCR}
                isDeleteCR={isDeleteCR}
                isCRCompleted={isCRCompleted}
                isCRRejected={isCRRejected}
                setEditingTextProposal={setEditingTextProposal}
                toggleProposalDiffing={toggleProposalDiffing}
                diffingProposalActive={isModuleDiffingProposalActive}
            />
        ),
    };

    const draftView = {
        header: (
            <ModuleVersionHeader
                text={showTitleInDraftTab ? combineStrings([moduleVersionNumbering, moduleVersionTitle]) : ''}
            />
        ),
        content: (
            <DraftView
                document={contextVersionToEdit}
                changeRequest={changeRequest}
                crModuleVersion={
                    moduleVersionTitle === t('reader.moduleVersion.notFound') || isDeleteCR ? null : crModuleVersion
                }
                setIsEditing={setEditing}
                isDiffingActive={isModuleDiffingDraftActive}
                isTitleChanged={isTitleChanged}
                isEditContentCR={isEditContentCR}
                isAddModuleCR={isAddModuleCR}
                isDeleteCR={isDeleteCR}
                isCRInImplementationPhase={isCRInImplementationPhase}
                isCRInOrAfterImplementationPhaseButNotRejected={isCRInOrAfterImplementationPhaseButNotRejected}
                onUnsavedChanges={onUnsavedChanges}
                toggleDiffing={toggleDraftDiffing}
            />
        ),
    };

    const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: ChangeRequestSplitViewTab) => {
        setSelectedTab(newValue);
    };

    const handleActualTabClick = (event: React.MouseEvent<{}>) => {
        if (isEditingTextProposal) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    const getViews = () => {
        if (selectedTab === ChangeRequestSplitViewTab.ACTUAL) {
            return [actualView, draftView];
        }

        if (selectedTab === ChangeRequestSplitViewTab.PROPOSAL) {
            return [proposalView, draftView];
        }
        return [actualView, draftView];
    };

    const openDocButtonRender = () => {
        return (
            <IconButton
                component="span"
                className="open-doc"
                data-testid={'changeRequestSplitViewTabOpenDoc'}
                children={<Document />}
                onClick={openDoc}
            />
        );
    };

    const handlePinButtonClick = (key: ChangeRequestSplitViewTab) => {
        setSelectedTab(key);
        dispatch(
            userActions.updateUserSettings({
                userSettings: {
                    ...userSettings,
                    uiSettings: {
                        ...userSettings.uiSettings,
                        defaultChangeRequestSplitViewTab: key.toUpperCase() as ChangeRequestSplitViewTab,
                    },
                },
            })
        );
    };

    const pinButtonRender = (key: ChangeRequestSplitViewTab) => {
        return (
            <PinButton
                isPinned={defaultChangeRequestSplitViewTab === key}
                onClickPin={() => {
                    handlePinButtonClick(key);
                }}
                dataTestId={`changeRequestSplitViewTabPinButton-${key?.toLowerCase()}`}
            ></PinButton>
        );
    };

    const renderTabLabel = (name: string, tabType: ChangeRequestSplitViewTab) => {
        return (
            <div className={'tab-item-label'}>
                {pinButtonRender(tabType)}
                <span>{name}</span>
                {tabType === ChangeRequestSplitViewTab.ACTUAL && !isAddModuleCR && openDocButtonRender()}
            </div>
        );
    };

    const switchableTabs: SwitchableTab[] = [
        {
            key: ChangeRequestSplitViewTab.ACTUAL,
            label: renderTabLabel(t('workflow.changeRequest.tabs.actual'), ChangeRequestSplitViewTab.ACTUAL),
            dataTestId: 'changeRequestSplitViewTabActual',
            className: 'actual-tab',
            disabled: isEditingTextProposal,
        },
        {
            key: ChangeRequestSplitViewTab.PROPOSAL,
            label: renderTabLabel(t('workflow.changeRequest.tabs.proposal'), ChangeRequestSplitViewTab.PROPOSAL),
            dataTestId: 'changeRequestSplitViewTabProposal',
            className: 'proposal-tab',
            disabled: false,
        },
    ];

    return !hasDraftDocsAccess ? (
        <div className="change-request__split-view">
            <Empty className="content empty" variant="text" text={t('workflow.changeRequest.errors.noViewRole')} />
        </div>
    ) : contextVersionToEdit ? (
        <div className="change-request__split-view">
            <div className="tabs-wrapper">
                <MaterialTabs
                    className="tabs-container left-tabs"
                    variant="fullWidth"
                    value={selectedTab}
                    indicatorColor="primary"
                    onChange={handleTabChange}
                >
                    {switchableTabs.map(({ key, label, className, dataTestId, disabled }) => (
                        <MaterialTab
                            data-testid={dataTestId}
                            className={className}
                            value={key}
                            label={label}
                            key={key}
                            disabled={disabled}
                            onClick={key === ChangeRequestSplitViewTab.ACTUAL ? handleActualTabClick : undefined}
                        />
                    ))}
                </MaterialTabs>
                <div style={{ width: '1rem' }} />
                <MaterialTabs
                    value={0}
                    className="tabs-container draft-tabs"
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <MaterialTab
                        className="draft-tab"
                        label={
                            <div className={'tab-item-label'}>
                                <span>{t('workflow.changeRequest.tabs.draft')}</span>
                            </div>
                        }
                        disabled
                    />
                </MaterialTabs>
            </div>
            <SplitView className="tab-view" views={getViews()} />
        </div>
    ) : (
        <Spinner />
    );
};
