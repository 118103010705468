import {
    appSettingsActions,
    docsActions,
    Empty,
    getModuleByContextOid,
    IApiContextVersionMetadata,
    IApiModuleVersion,
    IDocumentStatus,
    Layout,
    ModuleVersionContent,
    ModuleVersionHeader,
    ModuleVersionTitle,
    ModuleViewDiffingSwitch,
    Spinner,
    SplitView,
    UrlSchemeUtils,
    useQuery,
} from '@yonder-mind/ui-core';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Paper, Typography } from '@material-ui/core';
import { useSelectorUiWeb } from '../../store';

export interface UrlParam {
    contextVersionOid: string;
}

export const ModuleDiffing: React.FC = () => {
    const [prevModuleVersion, setPrevModuleVersion] = React.useState<IApiModuleVersion | null>(null);
    const [currentModuleVersion, setCurrentModuleVersion] = React.useState<IApiModuleVersion | null>(null);
    const [currentDocumentOid, setCurrentDocumentOid] = React.useState('');
    const [currentContextVersion, setCurrentContextVersion] = React.useState<IApiContextVersionMetadata | null>(null);
    const params: UrlParam = useParams();
    const { contextVersionOid } = params;
    const query = useQuery();
    const selectedModuleOid = query.get('moduleVersionOid');
    const dispatch = useDispatch();
    const { t } = useTranslation();

    React.useEffect(() => {
        dispatch(docsActions.contextVersionsMetadataRequested());
        dispatch(docsActions.documentRequested({ documentId: contextVersionOid, filters: [] }));
    }, []);

    const prevDocument = useSelectorUiWeb((state) => state.docs.documentCache[contextVersionOid]);
    const moduleCache = useSelectorUiWeb((state) => state.docs.modules);
    const documentCache = useSelectorUiWeb((state) => state.docs.documentCache);
    const contextVersionsMetadata = useSelectorUiWeb((state) => state.docs.currentContextVersionsMetadata);
    const hasDocError = useSelectorUiWeb((state) => state.docs.hasDocError);

    React.useEffect(() => {
        if (prevDocument && documentCache) {
            dispatch(docsActions.moduleVersionsByModuleOidRequested(selectedModuleOid));
            dispatch(
                docsActions.documentFlowRequested({
                    documentId: contextVersionOid,
                    contentFlowIndex: prevDocument.contentFlowIndex,
                })
            );
        }
    }, [prevDocument, documentCache]);

    const module =
        prevDocument && moduleCache ? getModuleByContextOid(moduleCache, selectedModuleOid, contextVersionOid) : null;

    React.useEffect(() => {
        if (contextVersionsMetadata && prevDocument) {
            const getCurrentDocument = contextVersionsMetadata.find(
                (item) => item.contextOid === prevDocument.contextVersion.contextOid
            );
            setCurrentDocumentOid(getCurrentDocument ? getCurrentDocument.oid : '');
            setCurrentContextVersion(getCurrentDocument);
        }
    }, [contextVersionsMetadata, prevDocument]);

    React.useEffect(() => {
        if (currentDocumentOid) {
            documentCache[currentDocumentOid] &&
                dispatch(
                    docsActions.documentFlowRequested({
                        documentId: currentDocumentOid,
                        contentFlowIndex: documentCache[currentDocumentOid].contentFlowIndex,
                    })
                );
            dispatch(docsActions.documentRequested({ documentId: currentDocumentOid, filters: [] }));
        }
    }, [currentDocumentOid]);

    React.useEffect(() => {
        if (selectedModuleOid && moduleCache && moduleCache[selectedModuleOid]?.versions) {
            setCurrentModuleVersion(moduleCache[selectedModuleOid].versions[0]);
            if (moduleCache[selectedModuleOid]?.versions[1]) {
                setPrevModuleVersion(moduleCache[selectedModuleOid].versions[1]);
            }
        }
    }, [moduleCache, selectedModuleOid]);

    const renderHeaderTitle = (temporary: boolean, index: number) => {
        if (temporary && index === 1) return t('moduleVersionSplitScreen.temporarayRevision');
        if (index === 0) return t('workflow.changeRequest.views.previous');
        return t('workflow.changeRequest.views.current');
    };

    const isModuleDiffingActive = useSelectorUiWeb((state) => state.appSettings.moduleDiffingDraft);
    const toggleDiffing = () => {
        dispatch(appSettingsActions.saveDiffingSettingsRequested(!isModuleDiffingActive));
    };

    const isDocumentInDraft = currentContextVersion?.status === IDocumentStatus.DRAFT;

    const temporaryContentAvailable =
        currentContextVersion?.temporaryRevision && !!module && !!currentModuleVersion?.temporaryRevision;

    const useTemporaryContent = isDocumentInDraft
        ? temporaryContentAvailable
        : temporaryContentAvailable &&
          !!currentModuleVersion &&
          !!currentModuleVersion?.temporaryRevision &&
          moment(moment.now()).isBetween(
              moment(currentModuleVersion?.temporaryRevision?.temporaryEffectiveFrom),
              moment(currentModuleVersion?.temporaryRevision?.temporaryEffectiveTo)
          );

    return (
        <Layout title="Module diffing" variant="module-diffing">
            <Paper className="paper" style={{ width: '100%' }}>
                {document && currentModuleVersion ? (
                    <SplitView
                        views={
                            [prevModuleVersion, currentModuleVersion].map((version, i) =>
                                version
                                    ? {
                                          header: (
                                              <ModuleVersionHeader
                                                  text={renderHeaderTitle(Boolean(useTemporaryContent), i)}
                                                  diffingAction={
                                                      i === 1 &&
                                                      currentModuleVersion &&
                                                      currentModuleVersion.diffContent ? (
                                                          <ModuleViewDiffingSwitch
                                                              action={toggleDiffing}
                                                              isActive={isModuleDiffingActive}
                                                          />
                                                      ) : null
                                                  }
                                              />
                                          ),
                                          content: (
                                              <div
                                                  className={`module-version module-version-item moduleDiffing ${
                                                      useTemporaryContent && i === 1 ? 'temp-revision' : null
                                                  }`}
                                              >
                                                  <ModuleVersionTitle
                                                      document={prevDocument.contextVersion}
                                                      moduleVersion={version}
                                                  />

                                                  <ModuleVersionContent
                                                      document={prevDocument.contextVersion}
                                                      moduleVersion={version}
                                                      links={module ? module.links : []}
                                                      showNoContentMessage={true}
                                                      key={`${version.oid}-${i}`}
                                                      useTemporaryContent={useTemporaryContent && i === 1}
                                                      htmlContent={
                                                          i === 1 &&
                                                          isModuleDiffingActive &&
                                                          currentModuleVersion &&
                                                          currentModuleVersion.diffContent
                                                              ? UrlSchemeUtils.replaceUrlScheme(
                                                                    currentModuleVersion.diffContent,
                                                                    window.location.origin
                                                                )
                                                              : ''
                                                      }
                                                  />
                                              </div>
                                          ),
                                      }
                                    : {
                                          header: <ModuleVersionHeader text={renderHeaderTitle(false, i)} />,
                                          content: (
                                              <Empty
                                                  variant="text"
                                                  text={t('moduleVersionSplitScreen.noPreviousVersion')}
                                              />
                                          ),
                                      }
                            ) as [any, any]
                        }
                    />
                ) : hasDocError ? (
                    <Typography className="no-items">{t('moduleVersionSplitScreen.noDocument')}</Typography>
                ) : (
                    <Spinner />
                )}
            </Paper>
        </Layout>
    );
};

export default ModuleDiffing;
