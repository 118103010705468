import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, TextField, Typography } from '@material-ui/core';
import {
    IApiModuleVersion,
    IWorkflowActivity,
    IWorkflowHistoricActivity,
    WorkflowUtils,
    YonderDialog,
} from '@yonder-mind/ui-core';
import { IWorkflowCrEditTitle } from '../../../../../interfaces';
import { useWorkflow } from '../../../../../context';

interface DraftViewEditTitleDialogProps {
    crEditTitle: IWorkflowCrEditTitle | undefined;
    changeRequest: IWorkflowActivity | IWorkflowHistoricActivity;
    setEditingCRTitle: (editing: boolean) => void;
    setCREditTitle: (title: IWorkflowCrEditTitle | undefined) => void;
    crModuleVersion: IApiModuleVersion;
}

export const DraftViewEditTitleDialog: React.FC<DraftViewEditTitleDialogProps> = ({
    crEditTitle,
    changeRequest,
    setCREditTitle,
    setEditingCRTitle,
    crModuleVersion,
}) => {
    const { t } = useTranslation();
    const { actions } = useWorkflow('cr');

    const handleCREditTitleChange = (e: React.ChangeEvent<any>) => {
        if (crEditTitle) {
            setCREditTitle({
                ...crEditTitle,
                moduleVersionTitle: e.target.value,
            });
        }
    };

    const saveCREditTitle = () => {
        setEditingCRTitle(false);
        setCREditTitle(undefined);

        if (crEditTitle) {
            actions.saveCRTitle(
                changeRequest.processInstanceId,
                WorkflowUtils.getContextVersionToEditOid(changeRequest.variables),
                changeRequest.variables.CHANGE_REQUEST_MODULE_ID,
                crEditTitle
            );
        }
    };

    const closeCREditTitleDialog = () => {
        setEditingCRTitle(false);
        setCREditTitle(undefined);

        actions.cancelEditCRTitle(
            changeRequest.processInstanceId,
            WorkflowUtils.getContextVersionToEditOid(changeRequest.variables),
            crModuleVersion.moduleOid
        );
    };

    return (
        <YonderDialog
            isOpen={true}
            title={<Typography variant="h6">{t('workflow.changeRequest.editTitle.title')}</Typography>}
            content={
                <Grid item={true}>
                    <TextField
                        value={crEditTitle.moduleVersionTitle}
                        variant="outlined"
                        fullWidth={true}
                        onChange={handleCREditTitleChange}
                        label={t('workflow.changeRequest.editTitle.content')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            }
            primaryAction={t('form.actions.save')}
            secondaryAction={t('form.actions.cancel')}
            onConfirm={saveCREditTitle}
            onCancel={closeCREditTitleDialog}
            onClose={closeCREditTitleDialog}
        />
    );
};
