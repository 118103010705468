import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
    authSelector,
    clipboardActions,
    ContentSchema,
    createApiLink,
    createFontoLinkItem,
    createURLForXMLEditor,
    docsActions,
    filesActions,
    FontoLinkItem,
    FontoPostMessageConnector,
    FullContentEditor,
    IApiContextVersion,
    IApiLink,
    IApiModuleVersion,
    IModuleLinkTarget,
    isModuleOidInCurrentContextVersion,
    IWorkflowActivity,
    IWorkflowHistoricActivity,
    LinkProperties,
    ModuleStructureType,
    Spinner,
    useClipboard,
    useCurrentContextType,
    WorkflowUtils,
    YonderButton,
} from '@yonder-mind/ui-core';
import { useWorkflow } from '../../../../../context';
import { IWebApplicationStore, IWorkflowCrEditContent } from '../../../../../interfaces';
import { importJobActions } from '../../../../../store';
import { ApiConstants } from '../../../../../utils';
import { adjustEditorHeight, adjustEditorHeightOnResizeListenerHandler } from '../AdjustEditorHeight';

interface DraftViewContentEditorProps {
    document: IApiContextVersion;
    changeRequest: IWorkflowActivity | IWorkflowHistoricActivity;
    crEditContent: IWorkflowCrEditContent | undefined;
    crModuleVersion: IApiModuleVersion;
    closeCREditContentEditor: () => void;
    onUnsavedChanges: (unsavedChanges: boolean) => void;
    setCREditContent: (crEditContent: IWorkflowCrEditContent | undefined) => void;
    setEditingCRContent: (editing: boolean) => void;
    isDraftEditable: boolean;
}

export const DraftViewContentEditor: React.FC<DraftViewContentEditorProps> = ({
    document,
    changeRequest,
    crEditContent,
    crModuleVersion,
    closeCREditContentEditor,
    onUnsavedChanges,
    setCREditContent,
    setEditingCRContent,
    isDraftEditable,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const currentContextType = useCurrentContextType(document);
    const { moduleLinkTargets, requestModuleLinkTargets } = useClipboard();
    const { actions } = useWorkflow('cr');

    const froalaEditorRef = useRef(null);

    const userInfo = useSelector(authSelector.userInfo);
    const fileList = useSelector((store: IWebApplicationStore) => store.files.fileList);
    const isLoadingSaveCr = useSelector((store: IWebApplicationStore) => store.workflow.changeRequest.isLoadingSaveCr);
    const isLoadingTransformXML = useSelector((store: IWebApplicationStore) => store.docs.isLoadingTransformXml);

    const isXMLDocument = currentContextType?.contentSchema === ContentSchema.AIRBUS;

    useEffect(() => {
        if (fileList.length === 0) {
            dispatch(filesActions.fileListRequested());
        }
    }, []);

    useEffect(adjustEditorHeightOnResizeListenerHandler, []);

    useEffect(() => {
        adjustEditorHeight();
    }, [window.document.querySelector('.fr-toolbar')?.clientHeight]);

    const handleLinkCreate = (link: IApiLink) => {
        if (crEditContent) {
            setCREditContent({
                ...crEditContent,
                links: [...(crEditContent.links || []), link],
            });
        }
    };
    const handleLinkUpdate = (link: IApiLink) => {
        if (crEditContent) {
            setCREditContent({
                ...crEditContent,
                links: [...(crEditContent.links || []).filter((l: IApiLink) => l.oid !== link.oid), link],
            });
        }
    };
    const handleLinkDelete = (linkOid: string) => {
        if (crEditContent) {
            setCREditContent({
                ...crEditContent,
                links: (crEditContent.links || []).filter((l: IApiLink) => l.oid !== linkOid),
            });
        }
    };

    const handleFileUploadTypeError = (fileExtension: string) => {
        enqueueSnackbar(
            t('common.notifications.fileUploadTypeError.message', { fileExtension: fileExtension?.toUpperCase() }),
            {
                variant: 'warning',
            }
        );
    };

    const handleCREditContentChange = (change: string, xmlChange?: string) => {
        if (crEditContent) {
            setCREditContent({
                ...crEditContent,
                moduleVersionContent: change,
                xmlContent: xmlChange,
            });
            onUnsavedChanges(true);
        }
    };

    const saveCREditContent = () => {
        const editor = froalaEditorRef.current?.editor;
        let moduleVersionContentToSave = crEditContent?.moduleVersionContent;
        if (editor?.codeView.isActive()) {
            // need to toggle code view to get the latest content
            // https://github.com/froala/wysiwyg-editor/issues/113
            editor.codeView.toggle();
            moduleVersionContentToSave = editor.html.get();
        }

        const contextVersionToEditOid = WorkflowUtils.getContextVersionToEditOid(changeRequest.variables);
        dispatch(importJobActions.importJobLockRequested(contextVersionToEditOid));
        if (crEditContent && isDraftEditable) {
            actions.saveCRContent(
                changeRequest.processInstanceId,
                WorkflowUtils.getContextVersionToEditOid(changeRequest.variables),
                WorkflowUtils.getEditModuleVersionOid(changeRequest.variables),
                { ...crEditContent, moduleVersionContent: moduleVersionContentToSave }
            );
        } else {
            setEditingCRContent(false);
            actions.cancelEditCRContent(
                changeRequest.processInstanceId,
                WorkflowUtils.getContextVersionToEditOid(changeRequest.variables),
                WorkflowUtils.getEditModuleVersionOid(changeRequest.variables)
            );
        }
        onUnsavedChanges(false);
    };

    const postMessageEventHandler = React.useCallback(
        (event: MessageEvent) => {
            const getXMLContent = (forwardDocumentToFonto: (xmlContent: string) => void) => {
                forwardDocumentToFonto(crEditContent.xmlContent);
            };

            const updateXMLContent = (content: string) => {
                dispatch(
                    docsActions.transformXmlToHtmlRequested({
                        contentSchema: currentContextType?.contentSchema,
                        xmlContent: content,
                        handleChangesCallback: ({ xmlContent, htmlContent }) => {
                            handleCREditContentChange(htmlContent, xmlContent);
                        },
                    })
                );
            };

            const getMediaVersion = (
                mediaVersionOid: string,
                forwardMediaVersionToFonto: (mediaContent: Blob) => void
            ) => {
                dispatch(docsActions.mediaVersionRequested(mediaVersionOid, forwardMediaVersionToFonto));
            };

            const uploadMediaVersion = (file: FormData, forwardFileIdToFonto: (id: string) => void) => {
                dispatch(docsActions.uploadMediaVersionRequested(file, forwardFileIdToFonto));
            };

            const createLink = (link: LinkProperties) => {
                const apiLink = createApiLink(link, crModuleVersion.moduleOid, document.owner);
                handleLinkCreate(apiLink);
            };

            const getClipboardItemsAsLinks = (
                forwardLinks: (fontoLinkItems: FontoLinkItem[]) => void,
                isModuleOidInCurrentContextVersion: (moduleOid: string) => boolean
            ) => {
                const forwardModuleLinkTargetsAsFontoLinkItems = (moduleLinkTargets: IModuleLinkTarget[]) => {
                    const fontoLinkItems: FontoLinkItem[] = moduleLinkTargets
                        .filter(
                            (moduleLinkTargets) =>
                                moduleLinkTargets.structureType == ModuleStructureType.AIRBUS_INVARIANT
                        )
                        .map((moduleLinkTarget) =>
                            createFontoLinkItem(moduleLinkTarget, isModuleOidInCurrentContextVersion)
                        );
                    forwardLinks(fontoLinkItems);
                };

                dispatch(clipboardActions.moduleLinkTargetsRequested(forwardModuleLinkTargetsAsFontoLinkItems));
            };

            FontoPostMessageConnector.xmlEditorPostMessageHandler(
                event,
                getXMLContent,
                updateXMLContent,
                getMediaVersion,
                uploadMediaVersion,
                getClipboardItemsAsLinks,
                createLink,
                (moduleOid: string) => isModuleOidInCurrentContextVersion(moduleOid, document)
            );
        },
        [currentContextType, crModuleVersion, crEditContent]
    );

    useEffect(() => {
        window.addEventListener('message', postMessageEventHandler);
        return () => window.removeEventListener('message', postMessageEventHandler);
    }, [postMessageEventHandler]);

    return (
        <>
            {isXMLDocument ? (
                <iframe
                    src={createURLForXMLEditor(currentContextType.contentSchema, crModuleVersion.oid)}
                    width="100%"
                    height="100%"
                    style={{ border: 0, maxWidth: '100%' }}
                    allowFullScreen
                ></iframe>
            ) : (
                <FullContentEditor
                    froalaEditorRef={froalaEditorRef}
                    username={userInfo.preferred_username}
                    document={document}
                    moduleVersion={{
                        ...crModuleVersion,
                        content: crEditContent.moduleVersionContent,
                    }}
                    links={crEditContent.links}
                    fileList={fileList}
                    mediaUploadUrl={ApiConstants.uploadMedia().toString()}
                    moduleLinkTargets={moduleLinkTargets}
                    requestModuleLinkTargets={requestModuleLinkTargets}
                    onChange={handleCREditContentChange}
                    onLinkCreate={handleLinkCreate}
                    onLinkUpdate={handleLinkUpdate}
                    onLinkDelete={handleLinkDelete}
                    onFileUploadTypeError={handleFileUploadTypeError}
                />
            )}

            <div className="content-editor__actions">
                <YonderButton variant="text" onClick={closeCREditContentEditor}>
                    {t('form.actions.cancel')}
                </YonderButton>
                <div className="grow" />
                <YonderButton
                    variant="text"
                    onClick={saveCREditContent}
                    disabled={isLoadingSaveCr || isLoadingTransformXML}
                >
                    {isLoadingSaveCr ? <Spinner size={'1rem'} /> : t('form.actions.save')}
                </YonderButton>
            </div>
        </>
    );
};
