import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoOutlined } from '@material-ui/icons';
import {
    createParagraphItems,
    CRName,
    Empty,
    IApiContextVersion,
    IApiModuleVersion,
    IWorkflowActivity,
    IWorkflowHistoricActivity,
    WorkflowUtils,
} from '@yonder-mind/ui-core';
import { ModuleVersionContentHighlighter } from '../../../ModuleVersion';

interface ModuleVersionViewProps {
    document: IApiContextVersion;
    changeRequest: IWorkflowActivity | IWorkflowHistoricActivity;
    moduleVersion: IApiModuleVersion | null;
}

export const ModuleVersionView: React.FC<ModuleVersionViewProps> = ({ document, changeRequest, moduleVersion }) => {
    const { t } = useTranslation();
    const isAddModuleCR = WorkflowUtils.isChangeRequestTypeOf(changeRequest, CRName.ADD_MODULE);
    const isEditTitleCR = WorkflowUtils.isChangeRequestTypeOf(changeRequest, CRName.EDIT_TITLE);
    const addModuleCRMessage = createParagraphItems(t('workflow.changeRequest.infoMessages.addModuleBase')).map(
        (item, index) => <p key={index}>{item}</p>
    );
    return (
        <div className={`module-version module-version-item ${isEditTitleCR ? 'faded-out' : ''}`}>
            {isAddModuleCR ? (
                <Empty
                    className="content empty"
                    variant="standard"
                    text={addModuleCRMessage}
                    icon={<InfoOutlined color={'primary'} />}
                />
            ) : moduleVersion ? (
                <ModuleVersionContentHighlighter
                    doc={document}
                    moduleVersion={moduleVersion}
                    crSelections={[
                        {
                            moduleVersionOid: moduleVersion.oid,
                            location: changeRequest.variables.MARK_LOCATION,
                            textContent: changeRequest.variables.MARK_CONTENT,
                        },
                    ]}
                    showBubbleHighlights={false}
                    autofocus={true}
                />
            ) : null}
        </div>
    );
};
