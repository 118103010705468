import dayjs from 'dayjs';
import { FileDropMetadata, FileDropTableItem, ImportedFiles } from '../domain/types';
import { FileContextMetadata, UpdateFileContextMetadata, UpdateFileRevisionMetadata } from '@yonder-mind/ui-core';

export const DEFAULT_START_DATE: dayjs.Dayjs = dayjs();
export const DEFAULT_END_DATE: any = null;
export const OFFSET_DATE_TIME_MAX = 31556889832694400;

export const getMetadataFromTableItem = (item: FileDropTableItem): FileDropMetadata => {
    return {
        contextOid: item.fileIdToUpdate || item.key,
        revisionOid: item.revisionOid,
        endDate: item.endDate,
        isDurationOpen: item.isDurationOpen,
        startDate: item.startDate,
        name: item.name,
        tagOids: item.tagOids,
        users: item.users,
        fileType: item.type,
    };
};

export const getCurrentMetadata = (selectedFileKeys: string[], importedFiles: ImportedFiles) =>
    selectedFileKeys.length > 0 && importedFiles[selectedFileKeys[0]]
        ? getMetadataFromTableItem(importedFiles[selectedFileKeys[0]])
        : ({} as FileDropMetadata);

export const getFormDataFromFileForApi = (selectedFile: FileDropTableItem) => {
    const formData = new FormData();

    formData.set('file', selectedFile.file);
    formData.set('effectiveFrom', selectedFile.startDate.toString());
    formData.set(
        'effectiveTo',
        !selectedFile.endDate ? OFFSET_DATE_TIME_MAX.toString() : selectedFile.endDate.toString()
    );
    formData.set('title', selectedFile.name);
    formData.set('isMemo', selectedFile.isMemo ? selectedFile.isMemo.toString() : 'false');
    selectedFile.tagOids && formData.set('tagIds', selectedFile.tagOids.join(','));
    selectedFile.users && formData.set('userIds', selectedFile.users.join(','));
    return formData;
};

export const getRevisionMetadataFromSelectedFile = (selectedFile: FileDropTableItem): UpdateFileRevisionMetadata => {
    return {
        fileContextOid: selectedFile.key,
        fileRevisionOid: selectedFile.revisionOid,
        effectiveFrom: selectedFile.startDate,
        effectiveTo: selectedFile.endDate ? selectedFile.endDate : OFFSET_DATE_TIME_MAX,
        title: encodeURIComponent(selectedFile.name),
        file: selectedFile.file,
    };
};

export const getContextMetadataFromSelectedFile = (selectedFile: FileDropTableItem): UpdateFileContextMetadata => {
    return {
        fileContextOid: selectedFile.fileIdToUpdate || selectedFile.key,
        tagIds: selectedFile.tagOids,
        userIds: selectedFile.users,
    };
};

export const getFileDropTableItemFromApiMetadata = (apiMetadata: FileContextMetadata): FileDropTableItem => {
    const latestRevision = apiMetadata.revisions[apiMetadata.revisions.length - 1];
    return {
        revisionOid: latestRevision.oid,
        startDate: latestRevision.effectiveFrom,
        endDate: latestRevision.effectiveTo < Number.MAX_SAFE_INTEGER ? latestRevision.effectiveTo : null,
        uploadDate: latestRevision.fileMetadata?.originalUploadDate
            ? latestRevision.fileMetadata.originalUploadDate
            : null,
        isDurationOpen: latestRevision.effectiveTo > Number.MAX_SAFE_INTEGER,
        tagOids: apiMetadata.tagIds,
        users: apiMetadata.userIds,
        key: apiMetadata.oid,
        // @ts-ignore
        type: latestRevision.fileMetadata?.mimeType,
        name: latestRevision.title,
    };
};

export const checkFileRevisionChanges = (file: FileDropTableItem, fileList: FileContextMetadata[]) => {
    const originalFileRevision = fileList
        .find((fileListFile) => fileListFile.oid === file.key)
        .revisions.find((revision) => revision.oid === file.revisionOid);
    return (
        originalFileRevision.title !== file.name ||
        originalFileRevision.effectiveFrom !== file.startDate ||
        originalFileRevision.effectiveTo !== file.endDate
    );
};

export const checkFileContextChanges = (file: FileDropTableItem, fileList: FileContextMetadata[]) => {
    const originalFileContext = fileList.find(
        (fileListFile) => fileListFile.oid === file.key || fileListFile.oid === file.fileIdToUpdate
    );
    return originalFileContext
        ? originalFileContext.userIds !== file.users || originalFileContext.tagIds !== file.tagOids
        : false;
};
