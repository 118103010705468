import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    authSelector,
    DashboardCard,
    DocumentFilterVariables,
    Documents as DocumentsIcon,
    DocumentsCardSecondaryHeader,
    FileTypeEnum,
    filterAndSortActions,
    filterDocumentsTabConfig,
    FilterUtils,
    FoldersList,
    IDocumentStatus,
    LastUsedList,
    SortUtils,
    userSelector,
    YonderList,
} from '@yonder-mind/ui-core';
import { IWebApplicationStore } from '../../../interfaces';

export const DocumentsCard = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [filtersActive, setFiltersActive] = useState(true);

    const hasDraftDocsAccess = useSelector(authSelector.hasDraftDocsAccess);
    const documentsActiveTab = useSelector(userSelector.getSelectDashboardTab(t('dashboard.cards.documents.title')));

    const {
        docs,
        hasErrorDocs,
        isLoadingDocs,
        isFirstLoadingDocs,
        folders,
        filteredDocumentsDataWithMemos,
        isLoadingTenantSettings,
    } = useSelector((state: IWebApplicationStore) => {
        return {
            docs: state.docs.currentContextVersionsMetadata,
            hasErrorDocs: state.docs.hasError,
            isLoadingDocs: state.docs.isLoading,
            isFirstLoadingDocs: state.docs.isFirstLoading,
            folders: state.folder.folders,
            filteredDocumentsDataWithMemos: state.filterAndSort.filteredDocumentsData,
            isLoadingTenantSettings: state.tenantSettings.isLoading,
        };
    });

    const filteredDocumentsData = useMemo(() => {
        return {
            ...filteredDocumentsDataWithMemos,
            documents: filteredDocumentsDataWithMemos.documents,
        };
    }, [filteredDocumentsDataWithMemos]);

    const documentsDraftCount = useMemo(() => {
        if (docs) {
            return docs.filter((doc) => doc.status === IDocumentStatus.DRAFT).length;
        }
        return 0;
    }, [docs]);

    const documentsReleasedCount = useMemo(() => {
        if (docs) {
            return docs.filter(
                (doc) => doc.status === IDocumentStatus.RELEASED && doc.fileMetadata?.mimeType !== FileTypeEnum.html
            ).length;
        }
        return 0;
    }, [docs]);

    const filteredDocsCount = useMemo(() => {
        const {
            activeFilters: { status: activeStatusFilter },
            documents: filteredDocuments,
        } = filteredDocumentsData;

        const filterStatusesDocCount = {
            [IDocumentStatus.DRAFT]: documentsDraftCount,
            [IDocumentStatus.RELEASED]: documentsReleasedCount,
        };

        if (activeStatusFilter in filterStatusesDocCount) {
            if (filteredDocuments.length === filterStatusesDocCount[activeStatusFilter]) {
                return filterStatusesDocCount[activeStatusFilter];
            }
            return `${filteredDocuments.length}/${filterStatusesDocCount[activeStatusFilter]}`;
        }

        return docs.length;
    }, [docs, filteredDocumentsData.documents.length]);

    useEffect(() => {
        const activeStatusFilter = filteredDocumentsData.activeFilters.status || IDocumentStatus.RELEASED;
        dispatch(filterAndSortActions.documentsFilterByCriteriaRequested(activeStatusFilter, 'status', undefined));
    }, [docs]);

    useEffect(() => {
        setFiltersActive(true);
        const documentsList = FilterUtils.filterDocuments(docs, filteredDocumentsData.activeFilters, true);
        dispatch(
            filterAndSortActions.saveFilteredDocuments(
                SortUtils.sortDocuments(documentsList, filteredDocumentsData.activeSort)
            )
        );
    }, [filteredDocumentsData.activeFilters, filteredDocumentsData.activeSort]);

    useEffect(() => {
        if (!isFirstLoadingDocs && !isLoadingDocs && docs.length > 0) {
            const { activeFilters, activeSort } = filteredDocumentsData;
            dispatch(filterAndSortActions.saveFilterAndSortDocumentsValues(activeFilters, activeSort));
        }
    }, [filteredDocumentsData]);

    const userAccessFilterFields = filterDocumentsTabConfig.fields.filter(
        (field) => !(field.param === DocumentFilterVariables.status && !hasDraftDocsAccess)
    );

    const displayFlatList =
        filteredDocumentsData.activeFilters.documentName ||
        (filteredDocumentsData.activeFilters.owner && filteredDocumentsData.activeFilters.owner.length > 0) ||
        (filteredDocumentsData.activeFilters.documentClass &&
            filteredDocumentsData.activeFilters.documentClass.length > 0) ||
        filteredDocumentsData.activeFilters.status !== IDocumentStatus.RELEASED;

    const filterVisibleAtTabIndex = [0];

    return (
        <DashboardCard
            isLoading={isLoadingDocs || isFirstLoadingDocs || isLoadingTenantSettings}
            hasError={hasErrorDocs}
            primaryHeader={t('dashboard.cards.documents.title')}
            secondaryHeader={
                <DocumentsCardSecondaryHeader
                    documentsActiveTab={documentsActiveTab}
                    filteredDocsCount={filteredDocsCount}
                    filteredDocumentsData={filteredDocumentsData}
                />
            }
            icon={<DocumentsIcon size="large" />}
            filterVisibleAtTabIndex={filterVisibleAtTabIndex}
            tabs={[
                {
                    component: displayFlatList ? (
                        <YonderList
                            variant="docs"
                            items={filteredDocumentsData.documents}
                            divider="inset"
                            useVirtualList
                            isLoading={isLoadingDocs || isFirstLoadingDocs}
                            dataTestId="dashboardLibraryCardTabDocsList"
                        />
                    ) : (
                        <FoldersList
                            docs={filteredDocumentsData.documents}
                            folders={folders}
                            activeSort={filteredDocumentsData.activeSort}
                        />
                    ),
                    dataTestId: 'dashboardLibraryCardTabDocs',
                    label: t('dashboard.cards.documents.tabs.all'),
                    chips: {
                        filterAvailable: filtersActive,
                        filterType: filterDocumentsTabConfig.type,
                        filterValues: filteredDocumentsData.activeFilters,
                    },
                },
                {
                    component: <LastUsedList dataTestId="dashboardLibraryCardTabLastUsedList" />,
                    dataTestId: 'dashboardLibraryCardTabLastUsed',
                    label: t('dashboard.cards.documents.tabs.recent'),
                },
            ]}
            filterOptions={FilterUtils.getDocumentCardFilterOptions({
                filteredDocumentsData: filteredDocumentsData,
                t,
                userAccessFilterFields,
            })}
        />
    );
};
