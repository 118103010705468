import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs } from '@material-ui/core';
import isEqual from 'lodash/isEqual';
import {
    authSelector,
    changesActions,
    filesActions,
    FolderStructureUtils,
    IApplicationStore,
    Spinner,
    YonderButton,
} from '@yonder-mind/ui-core';
import { FileFolderAssignment } from './FolderPanel/FileFolderAssignment';
import { FileDropMetadata } from '../domain/types';
import { MetadataPanel, MetadataPanelType, MetadataValidation } from './MetadataPanel/MetadataPanel';
import { NoItems } from '../../../components';
import { IWebApplicationStore } from '../../../interfaces';
import { FileNotifications } from './NotificationsPanel/FileNotifications';
import { HistoryLogPanel } from './HistoryLogPanel/HistoryLogPanel';
import { OFFSET_DATE_TIME_MAX } from '../utils/metadataUtils';

interface IProps {
    type: MetadataPanelType;
    selectedFileKeys: string[];
    setMetadata: (metadata: FileDropMetadata) => void;
    currentMetadata: FileDropMetadata;
    onPublish: () => void;
    disabled?: boolean;
    cancelEditMode?: () => void;
}

export const MetadataTabs: React.FC<IProps> = ({
    type,
    selectedFileKeys,
    setMetadata,
    currentMetadata,
    onPublish,
    disabled,
    cancelEditMode,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const folders = useSelector((state: IApplicationStore) => state.folder.unprocessedFolders);
    const hasHistoryViewRole = useSelector(authSelector.hasHistoryViewRole);

    const [selectedTab, setSelectedTab] = React.useState<string>('metadata-tab');
    const [validation, setValidation] = useState<MetadataValidation>({ dateFrom: '', dateTo: '' });
    const [initialMetadata] = useState(currentMetadata);

    const { isLoading, assignedFilesToFolders, hasChangesNotificationSettings } = useSelector(
        (state: IWebApplicationStore) => {
            return {
                isLoading:
                    Object.values(state.files.fileUploadStatus).some((value) => value?.inProgress) ||
                    state.files.isLoading,
                assignedFilesToFolders: state.files.assignedFilesToFolders,
                hasChangesNotificationSettings: state.files.hasNotificationSettingsChanges,
            };
        }
    );

    useEffect(() => {
        if (currentMetadata.revisionOid && type !== 'import') {
            dispatch(changesActions.requestChanges('context', currentMetadata.revisionOid, null));
        }
    }, [currentMetadata?.revisionOid]);

    useEffect(() => {
        if (
            folders?.root?.children &&
            currentMetadata.contextOid &&
            !assignedFilesToFolders[currentMetadata.contextOid]
        ) {
            dispatch(
                filesActions.saveAssignedFilesToFolders({
                    contextOid: currentMetadata.contextOid,
                    selectedFolders: FolderStructureUtils.getAssignedFolders(
                        currentMetadata.contextOid,
                        folders.root.children
                    ),
                })
            );
        }
    }, [folders, currentMetadata.contextOid]);

    const setNewValidation = useCallback(
        (newValidation: (prevValidation: MetadataValidation) => Partial<MetadataValidation>) => {
            setValidation((prevValidation) => ({
                ...prevValidation,
                ...newValidation(prevValidation),
            }));
        },
        []
    );

    useEffect(() => {
        if (cancelEditMode) {
            const customIsEqual = (initialMetadata: any, currentMetadata: any) => {
                if (initialMetadata.isDurationOpen && currentMetadata.isDurationOpen) {
                    const initialEndDate =
                        initialMetadata.endDate === OFFSET_DATE_TIME_MAX ? null : initialMetadata.endDate;
                    const currentEndDate =
                        currentMetadata.endDate === OFFSET_DATE_TIME_MAX ? null : currentMetadata.endDate;
                    return isEqual(
                        { ...initialMetadata, endDate: initialEndDate },
                        { ...currentMetadata, endDate: currentEndDate }
                    );
                }
                return isEqual(initialMetadata, currentMetadata);
            };

            if (customIsEqual(initialMetadata, currentMetadata)) {
                !validation.isEqualToCurrentMetadata &&
                    setTimeout(() => setNewValidation(() => ({ isEqualToCurrentMetadata: true })), 0);
            } else {
                validation.isEqualToCurrentMetadata &&
                    setTimeout(() => setNewValidation(() => ({ isEqualToCurrentMetadata: false })), 0);
            }
        }
    }, [currentMetadata, setNewValidation, cancelEditMode, initialMetadata]);

    const handleChangeTab = (value: string) => {
        setSelectedTab(value);
    };

    const updateFolderAssignment = (folderOid: string, fileKey: string) => {
        const currentSelectedFolders = assignedFilesToFolders[fileKey];
        if (currentSelectedFolders && currentSelectedFolders.includes(folderOid)) {
            dispatch(
                filesActions.saveAssignedFilesToFolders({
                    contextOid: fileKey,
                    selectedFolders: currentSelectedFolders.filter((id: string) => id !== folderOid),
                })
            );
        } else {
            dispatch(
                filesActions.saveAssignedFilesToFolders({
                    contextOid: fileKey,
                    selectedFolders: currentSelectedFolders
                        ? [...currentSelectedFolders].concat(folderOid)
                        : [folderOid],
                })
            );
        }
    };

    const handleSelectedFolders = (folderOid: string) => {
        if (selectedFileKeys.length > 1) {
            selectedFileKeys.forEach((fileKey) => {
                updateFolderAssignment(folderOid, fileKey);
            });
        } else {
            updateFolderAssignment(folderOid, currentMetadata.contextOid);
        }
    };

    const hasChangesInFolderAssignment = folders
        ? !isEqual(
              FolderStructureUtils.getAssignedFolders(selectedFileKeys[0], folders?.root?.children),
              assignedFilesToFolders[currentMetadata.contextOid]
          )
        : true;

    return selectedFileKeys.length > 0 ? (
        <>
            <Tabs
                className="tab-switcher"
                indicatorColor="primary"
                textColor="primary"
                value={selectedTab}
                onChange={(_, value) => handleChangeTab(value)}
            >
                <Tab value="metadata-tab" label={t('fileDrop.metadataTabs.metadata')} data-testid="metadataTab" />
                <Tab value="structure-tab" label={t('fileDrop.metadataTabs.structure')} data-testid="foldersTab" />
                <Tab
                    value="notification-tab"
                    label={t('fileDrop.metadataTabs.notifications')}
                    data-testid="notificationsTab"
                />
                {hasHistoryViewRole && type === 'library' && (
                    <Tab
                        value="history-log-tab"
                        label={t('fileDrop.metadataTabs.history')}
                        data-testid="historyLogTab"
                    />
                )}
            </Tabs>
            <div className="metadata-tab-panel">
                <div className="metadata-header">
                    {cancelEditMode && !disabled && (
                        <YonderButton onClick={cancelEditMode} variant="text" className="file-drop-cancel-button">
                            {t('form.actions.cancel')}
                        </YonderButton>
                    )}
                    {!disabled && (
                        <YonderButton
                            onClick={onPublish}
                            variant="outlined"
                            data-testid="publish"
                            disabled={
                                !!(
                                    validation.dateFrom ||
                                    validation.dateTo ||
                                    validation.name ||
                                    (validation.isEqualToCurrentMetadata &&
                                        !hasChangesInFolderAssignment &&
                                        !hasChangesNotificationSettings)
                                )
                            }
                        >
                            {t('fileDrop.metadataPanel.header.publish')}
                            {isLoading && <Spinner style={{ width: '2em' }} />}
                        </YonderButton>
                    )}
                </div>
                <MetadataPanel
                    type={type}
                    selectedFileKeys={selectedFileKeys}
                    setMetadata={setMetadata}
                    currentMetadata={currentMetadata}
                    setNewValidation={setNewValidation}
                    disabled={disabled}
                    visible={selectedTab === 'metadata-tab'}
                />
                <FileFolderAssignment
                    selectedFolders={assignedFilesToFolders[currentMetadata.contextOid]}
                    setSelectedFolders={handleSelectedFolders}
                    folders={folders.root.children}
                    disabled={disabled}
                    visible={selectedTab === 'structure-tab'}
                />
                <FileNotifications
                    visible={selectedTab === 'notification-tab'}
                    currentMetadata={currentMetadata}
                    disabled={disabled}
                    isImportInProgress={type === 'import'}
                />
                <HistoryLogPanel contextOid={currentMetadata.contextOid} visible={selectedTab === 'history-log-tab'} />
            </div>
        </>
    ) : (
        <NoItems className="metadata-no-files-selected" message={t('fileDrop.metadataPanel.noFilesSelected')} />
    );
};
