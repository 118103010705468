import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropzoneInputProps } from 'react-dropzone';
import { IconButton, Menu, MenuItem, TableCell } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { FileTypeEnum } from '@yonder-mind/ui-core';

export interface KebabMenuProps {
    rename?: (itemKey: string) => void;
    remove: (itemKey: string) => void;
    updateFile?: (itemKey: string) => void;
    editMetadata?: (itemKey: string) => void;
    getInputProps?: <T extends DropzoneInputProps>(props?: T) => T;
    preview?: (itemKey: string) => void;
}

export const TableKebabMenu: React.FC<KebabMenuProps & { itemKey: string; itemType: FileTypeEnum }> = ({
    remove,
    editMetadata,
    rename,
    updateFile,
    getInputProps,
    preview,
    itemKey,
    itemType,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleKebabClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleKebabClose = () => {
        setAnchorEl(null);
    };

    const { t } = useTranslation();

    return (
        <TableCell size="small" className="kebab-menu-cell">
            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleKebabClose}>
                {preview && itemType !== FileTypeEnum.html && (
                    <MenuItem
                        onClick={() => {
                            handleKebabClose();
                            preview(itemKey);
                        }}
                        data-testid="preview"
                    >
                        {t('fileDrop.kebabMenu.preview')}
                    </MenuItem>
                )}
                {rename && (
                    <MenuItem
                        onClick={() => {
                            handleKebabClose();
                            rename(itemKey);
                        }}
                        data-testid="rename"
                    >
                        {t('fileDrop.kebabMenu.rename')}
                    </MenuItem>
                )}
                {editMetadata && (
                    <MenuItem
                        onClick={() => {
                            handleKebabClose();
                            editMetadata(itemKey);
                        }}
                        data-testid="edit"
                    >
                        {t('fileDrop.kebabMenu.edit')}
                    </MenuItem>
                )}
                {updateFile && itemType !== FileTypeEnum.html && (
                    <MenuItem
                        onClick={() => {
                            handleKebabClose();
                            updateFile(itemKey);
                        }}
                        data-testid="newRevision"
                    >
                        <input {...getInputProps()} />
                        {t('fileDrop.kebabMenu.newRevision')}
                    </MenuItem>
                )}
                {remove && (
                    <MenuItem
                        onClick={() => {
                            handleKebabClose();
                            remove(itemKey);
                        }}
                        data-testid="delete"
                    >
                        {t('fileDrop.kebabMenu.remove')}
                    </MenuItem>
                )}
            </Menu>
            <IconButton className="kebab-button" onClick={handleKebabClick} data-testid="kebabMenuButton">
                <MoreVert />
            </IconButton>
        </TableCell>
    );
};
