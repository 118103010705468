import React, { useEffect, useState } from 'react';
import {
    authSelector,
    fileDropActions,
    filesActions,
    folderActions,
    Layout,
    tenantSettingsActions,
    userActions,
    userSelector,
    useSelectorUiCore,
} from '@yonder-mind/ui-core';
import { Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FileDropTableItem } from './domain/types';
import { FileDropImport } from './FileDropImport';
import { FileDropLibrary } from './FileDropLibrary';

export const FileDrop: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const selectedTab = useSelector(userSelector.getSelectedFileDropTab);
    const [updatedFile, setUpdatedFile] = useState<FileDropTableItem>(null);

    useEffect(() => {
        dispatch(fileDropActions.fileDropTagsRequested());
        dispatch(fileDropActions.usersRequested());
        dispatch(tenantSettingsActions.tenantSettingsRequested());
        dispatch(folderActions.folderStructureRequested({ processResponse: false }));
    }, [dispatch]);

    const hasFileDropAdminRole = useSelector(authSelector.hasFileDropAdminRole);

    const { isLoading: isTenantSettingsLoading } = useSelectorUiCore((state) => ({
        ...state.tenantSettings,
    }));

    useEffect(() => {
        if (updatedFile && updatedFile.file && updatedFile.key) {
            dispatch(userActions.setSelectedFileDropTab('import-tab'));
        }
    }, [updatedFile]);

    useEffect(() => {
        if (selectedTab && selectedTab === 'library-tab') {
            dispatch(filesActions.fileListRequested());
        }
    }, [selectedTab]);

    return (
        <Layout title={t('dashboard.userMenu.fileDrop')} variant="file-drop">
            {hasFileDropAdminRole ? (
                <>
                    <Tabs
                        className="tab-switcher"
                        indicatorColor="primary"
                        textColor="primary"
                        value={selectedTab}
                        onChange={(_, value) => dispatch(userActions.setSelectedFileDropTab(value))}
                    >
                        <Tab value="import-tab" label={t('fileDrop.tabs.import')} data-testid="importTab" />
                        <Tab value="library-tab" label={t('fileDrop.tabs.library')} data-testid="libraryTab" />
                    </Tabs>
                    {!isTenantSettingsLoading && (
                        <div className="file-drop-page">
                            <FileDropLibrary
                                updatedFile={updatedFile}
                                setUpdatedFile={setUpdatedFile}
                                visible={selectedTab === 'library-tab'}
                            />
                            <FileDropImport
                                updatedFile={updatedFile}
                                setUpdatedFile={setUpdatedFile}
                                visible={selectedTab === 'import-tab'}
                            />
                        </div>
                    )}
                </>
            ) : (
                <div>{t('fileDrop.metadataPanel.notFound')}</div>
            )}
        </Layout>
    );
};
