import { TokenResponse } from '@openid/appauth';
import { Client as StompClient, Message } from '@stomp/stompjs';
import { AnyAction } from 'redux';
import { EventChannel } from 'redux-saga';
import { all, call, put, race, select, take, takeEvery, debounce } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { IAuthServiceApi, WorkflowUtils } from '@yonder-mind/ui-core';
import { processActions } from '../workflow/process';
import { getStompClient, subscribe } from './helper';
import * as actions from './websocket.actions';
import { websocketSelector } from './websocket.selector';
import { WorkflowTopic } from '../../config';
import { revisionActions } from '../workflow';

const isUUID = (id: string) => /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(id);

function* canCommentOnProcessRequestedWorker(action: AnyAction) {
    if (!isUUID(action.payload)) {
        return;
    }
    yield put(processActions.canCommentOnProcessRequested(action.payload));
}

function* topicSubscription(stompClient: StompClient, action: AnyAction): any {
    const { topic } = action.payload;

    let channel: null | EventChannel<any> = null;

    try {
        const subscriptionCount = yield select(websocketSelector.countSubscriptionsOnTopic, topic);

        if (subscriptionCount > 1) {
            return;
        }

        channel = subscribe(stompClient, topic);

        while (true) {
            const { receive, unsubscribe } = yield race({
                receive: take(channel),
                unsubscribe: take(getType(actions.unsubscribeFromTopic)),
            });

            if (receive) {
                yield put(actions.receivedMessage(topic, receive));
            }

            if (unsubscribe) {
                if (unsubscribe.payload.topic === topic) {
                    const remainingSubscriptionCount = yield select(websocketSelector.countSubscriptionsOnTopic, topic);
                    if (remainingSubscriptionCount === 0) {
                        break;
                    }
                }
            }
        }
    } catch (e) {
        console.error(e);
    } finally {
        if (channel) {
            channel.close();
        }
    }
}

export function* handleCommentMessage(message: Message) {
    const comment = JSON.parse(message.body);

    yield put(processActions.addCommentToProcessConfirmationReceived(comment.processInstanceId, comment));
}

export function* handleRevisionMessage(message: Message) {
    const revision = JSON.parse(message.body);
    const contextOid = WorkflowUtils.getContextOid(revision.variables);

    yield put(revisionActions.receivedNewRevision(contextOid, revision));
}

export function* handleProcessUpdateMessage(message: Message) {
    const processInstanceId = message.body;

    if (window.location.href.includes('workflow/cr')) {
        return;
    } else {
        yield put(processActions.updateProcess(processInstanceId));
        yield debounce(3000, processActions.canCommentOnProcessRequested, canCommentOnProcessRequestedWorker);
    }
}

export function* handleMessage(action: AnyAction) {
    const { topic, message } = action.payload;

    if (topic.startsWith(WorkflowTopic.COMMENT)) {
        yield call(handleCommentMessage, message);
    }

    if (topic.startsWith(WorkflowTopic.REVISION)) {
        yield call(handleRevisionMessage, message);
    }

    if (topic.startsWith(WorkflowTopic.PROCESS)) {
        yield call(handleProcessUpdateMessage, message);
    }
}

export default function* websocketSagas(api: IAuthServiceApi) {
    while (true) {
        let stompClient: StompClient | null;

        try {
            yield take(getType(actions.initSocket));
            const tokens: TokenResponse = yield call([api, api.getTokens]);

            if (!tokens) {
                throw new Error('User has not been authentication yet!');
            }

            stompClient = yield getStompClient(tokens);

            yield put(actions.initializedSocket());

            yield all([
                takeEvery(getType(actions.subscribeToTopic), topicSubscription, stompClient),
                takeEvery(getType(actions.receivedMessage), handleMessage),
            ]);
        } catch (e) {
            console.error(e);
        } finally {
            if (!stompClient) {
                stompClient.deactivate();
            }
        }
    }
}
